import React from "react"
import {Container} from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"

// import indexImage from "../images/monitoring-aerobarrier.jpg"

// import serviceArea from "../images/service-area.jpg"

const aboutTheAuthor = props => (
  <Layout>
        <Seo title="Get a Quote - Thank You"/>


        

        <Container className="mt-2 singleCol">
          <h1 className="text-center">Get a Quote - Thank You</h1>

          <p>
            Thank you for submitting a quote. We will be in contact shortly.  
          </p>

          </Container>
          
        
      </Layout>
);

export default aboutTheAuthor;

